import type { Dispatch, SetStateAction } from 'react';

import type { IPublication } from '../../../../features/publication';
import { isPaidModeAvailable } from '../../../../lib/feature-flags';
import type { SubscriberInputPublication } from '../../../modules/SubscriberFormInput/SubscriberFormInput.interface';

// this publication needs to be of type SubscriberInputPublication because it will render a SubscriberForm component
export function escapeText(rawText: string): string {
    return rawText?.replace(/'/g, '’');
}

export function formatPublication(publication: SubscriberInputPublication): string {
    return JSON.stringify({
        id: publication.id,
        title: escapeText(publication.title),
        description: publication.description ? escapeText(publication.description) : '',
        subscriberLists: publication.subscriberLists,
        isPaid: publication.isPaid,
    });
}

export async function handlePaidMode(
    publication: Pick<IPublication, 'id' | 'isPaid'>,
    setIsPublicationPaid: Dispatch<SetStateAction<boolean>>,
): Promise<void> {
    const isPublicationPaid = await isPaidModeAvailable(publication);
    setIsPublicationPaid(isPublicationPaid);
}
