import { NodeViewWrapper } from '@tiptap/react';
import type { Node } from 'prosemirror-model';
import React from 'react';

import type { IPublication } from '../../../../features/publication';
import { isValidJSON } from '../../../../lib/helper/helper';
import SubscribeToPaidOffer from '../../../modules/SubscriberForm/SubscribeToPaidOffer';

function parsePublicationForSubscribeToPaidOffer(
    publicationAsString: string,
): Pick<IPublication, 'id' | 'slug' | 'title'> {
    return JSON.parse(publicationAsString);
}

interface SubscribeToPaidOfferWrapperProps {
    node: Omit<Node, 'attrs'> & { attrs: { publication: string } };
}

export default function SubscribeToPaidOfferWrapper({
    node,
}: SubscribeToPaidOfferWrapperProps): JSX.Element | null {
    if (!isValidJSON(node.attrs.publication)) {
        return null;
    }

    return (
        <NodeViewWrapper>
            <SubscribeToPaidOffer
                publication={parsePublicationForSubscribeToPaidOffer(node.attrs.publication)}
            />
        </NodeViewWrapper>
    );
}
