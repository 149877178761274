import { mergeAttributes, Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import SubscribeFormWrapper from '.';

export const SubscribeFormName = 'subscribeFormWrapper';
export const SubscribeFormTag = 'subscribe-form-wrapper';

export default Node.create({
    name: SubscribeFormName,

    group: 'block',

    atom: true,

    addAttributes() {
        return {
            publication: {
                default: undefined,
            },
            postId: {
                default: undefined,
            },
        };
    },

    parseHTML() {
        return [
            {
                tag: SubscribeFormTag,
            },
        ];
    },

    renderHTML({ HTMLAttributes }) {
        return [SubscribeFormTag, mergeAttributes(HTMLAttributes)];
    },

    addNodeView() {
        return ReactNodeViewRenderer(SubscribeFormWrapper);
    },
});
