import type { Editor } from '@tiptap/react';
import type { Dispatch, SetStateAction } from 'react';
import { useForm } from 'react-hook-form';

import Card from '../../../basic/Card';
import Button from '../../../elements/Button';
import Label from '../../../elements/Forms/Label';
import TextInput from '../../../elements/Forms/TextInput';
import Heading2 from '../../../elements/Heading/Heading2';
import { ButtonLinkTag } from '../../Extensions/ButtonLinkWrapper/ExtensionButtonLinkWrapper';

type FormFields = {
    label: string;
    href: string;
};

interface FormCustomButtonProps {
    editor: Editor;
    visible: boolean;
    setVisible: Dispatch<SetStateAction<boolean>>;
}

export default function FormCustomButton({
    editor,
    visible,
    setVisible,
}: FormCustomButtonProps): JSX.Element {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<FormFields>({
        defaultValues: {
            label: '',
            href: '',
        },
    });

    function onSubmit(data: FormFields): void {
        editor
            .chain()
            .focus()
            .insertContent(
                `<${ButtonLinkTag} href=${data.href} label="${data.label}"></${ButtonLinkTag}>`,
            )
            .run();

        setVisible(false);
    }

    return (
        <>
            {visible && (
                <div className="md:w-[500px] flex flex-col border absolute top-12 bottom-0 p-2 z-10">
                    <Card>
                        <Heading2 className="!mt-0" level={1}>
                            Créer un bouton
                        </Heading2>
                        <form
                            onSubmit={handleSubmit(onSubmit)}
                            className="p-1 d-flex flex-column gap-2">
                            <div className="mt-4">
                                <Label htmlFor="label">label</Label>
                                <TextInput<FormFields>
                                    id="label"
                                    name="label"
                                    register={register}
                                    errorText={errors.label?.message}
                                    className="w-full"
                                />
                            </div>
                            <div className="mt-4">
                                <Label htmlFor="href">destination</Label>
                                <TextInput<FormFields>
                                    id="href"
                                    name="href"
                                    register={register}
                                    errorText={errors.href?.message}
                                    className="w-full"
                                />
                            </div>
                            <div>
                                <div className="flex justify-between">
                                    <Button color="alternative" onClick={() => setVisible(false)}>
                                        Cancel
                                    </Button>
                                    <Button type="submit">Créer</Button>
                                </div>
                            </div>
                        </form>
                    </Card>
                </div>
            )}
        </>
    );
}
