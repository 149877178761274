import { mergeAttributes, Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import ButtonLink from '.';

export const ButtonLinkName = 'linkButton';
export const ButtonLinkTag = 'link-button';

export default Node.create({
    name: ButtonLinkName,

    group: 'block',

    atom: true,

    addAttributes() {
        return {
            href: {
                default: '',
            },
            label: {
                default: '',
            },
        };
    },

    parseHTML() {
        return [
            {
                tag: ButtonLinkTag,
            },
        ];
    },

    renderHTML({ HTMLAttributes }) {
        return [ButtonLinkTag, mergeAttributes(HTMLAttributes)];
    },

    addNodeView() {
        return ReactNodeViewRenderer(ButtonLink);
    },
});
