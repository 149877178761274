import type { Editor } from '@tiptap/react';
import { EditorContent } from '@tiptap/react';

import type { IPost } from '../../features/post';
import classNames from '../../lib/classnames';
import Prose from '../basic/Prose';
import type { SubscriberInputPublication } from '../modules/SubscriberFormInput/SubscriberFormInput.interface';
import IsEditionMode from './isEditorModeContext';
import MenuBar from './MenuBar';

export interface TiptapProps {
    editor: Editor | null;
    publication?: SubscriberInputPublication;
    postId?: IPost['id'] | '__postId__';
}

export default function Tiptap({
    editor,
    publication,
    postId = '__postId__',
}: TiptapProps): JSX.Element {
    return (
        <>
            {editor?.isEditable && (
                <MenuBar editor={editor} publication={publication} postId={postId} />
            )}

            <Prose>
                <IsEditionMode.Provider value={editor?.isEditable || false}>
                    <EditorContent
                        editor={editor}
                        className={classNames({
                            tiptapViewer: !editor?.isEditable,
                        })}
                    />
                </IsEditionMode.Provider>
            </Prose>
        </>
    );
}
