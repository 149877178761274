import routes from '../../../../config/routes';
import type { IPost } from '../../../../features/post';
import classNames from '../../../../lib/classnames';
import Card from '../../../basic/Card';
import Heading2 from '../../../elements/Heading/Heading2';
import Link from '../../../elements/Link';
import IllustrationSubStar from '../../../svgs/illustrations/sub-star.svg';
import SubscribeToFreeOfferInput from '../../SubscriberFormInput';
import type { SubscriberInputPublication } from '../../SubscriberFormInput/SubscriberFormInput.interface';
import PayWallBlock from './PayWallBlock';

export interface PayWallProps {
    publication: SubscriberInputPublication;
    showHeaderBlur?: boolean;
    postId?: IPost['id'] | '__postId__';
}

// TODO this flag (showHeaderBlur) might be added later
export default function PayWall({
    showHeaderBlur = false,
    publication,
    // this postId is necessary to easily convert this paywall into a link to this post for the emails
    // eslint-disable-next-line unused-imports/no-unused-vars
    postId,
}: PayWallProps): JSX.Element {
    return (
        <div className={classNames({ 'relative w-full z-50 !-mt-[230px]': showHeaderBlur })}>
            <div
                className={classNames({
                    'grid justify-items-center content-center': showHeaderBlur,
                })}>
                {showHeaderBlur && <PayWallBlock />}
                <Card className="border border-blue-700">
                    <div className="grid grid-rows-2 grid-cols-none md:grid-rows-none md:grid-cols-2 gap-4">
                        <div className="flex flex-col gap-5">
                            <Heading2 className="!mt-0 !mb-0">
                                La suite de cet article est réservée aux abonnés payants.
                            </Heading2>
                            <SubscribeToFreeOfferInput publication={publication}>
                                <Link href={routes.login.getUrl()} className="text-base">
                                    Je suis déjà abonné
                                </Link>
                            </SubscribeToFreeOfferInput>
                        </div>
                        <IllustrationSubStar className="w-[200px] h-[200px] justify-self-center md:justify-self-end" />
                    </div>
                </Card>
            </div>
        </div>
    );
}
