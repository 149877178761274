import Color from '@tiptap/extension-color';
import Link from '@tiptap/extension-link';
import TextAlign from '@tiptap/extension-text-align';
import TextStyle from '@tiptap/extension-text-style';
import Underline from '@tiptap/extension-underline';
import Youtube from '@tiptap/extension-youtube';
import { generateHTML } from '@tiptap/html';
import StarterKit from '@tiptap/starter-kit';

import { isValidJSON } from '../../lib/helper/helper';
import ExtensionButtonLinkWrapper from './Extensions/ButtonLinkWrapper/ExtensionButtonLinkWrapper';
import CustomImage from './Extensions/CustomImage';
import ExtensionMailButtonLink from './Extensions/MailButtonLink/ExtensionMailButtonLink';
import ExtensionPaywallWrapper from './Extensions/PaywallWrapper/ExtensionPaywallWrapper';
import ExtensionSubscribeFormWrapper from './Extensions/SubscribeFormWrapper/ExtensionSubscribeFormWrapper';
import ExtensionSubscribeToPaidOfferWrapper from './Extensions/SubscribeToPaidOfferWrapper/ExtensionSubscribeToPaidOfferWrapper';
import ExtensionTwitterEmbed from './Extensions/TwitterEmbed/ExtensionTwitterEmbed';

export const extensions = [
    StarterKit,
    Underline,
    Link.configure({
        openOnClick: true,
        HTMLAttributes: {
            rel: '',
        },
    }),
    Youtube.configure({
        width: 100,
    }),
    TextStyle,
    Color,
    TextAlign.configure({
        types: ['heading', 'paragraph', 'image'],
        alignments: [
            'left',
            'right',
            'center',
            '-webkit-left',
            '-webkit-center',
            '-webkit-right',
            '-moz-left',
            '-moz-center',
            '-moz-right',
        ],
        defaultAlignment: 'left',
    }),
    CustomImage,
    ExtensionButtonLinkWrapper,
    ExtensionMailButtonLink,
    ExtensionPaywallWrapper,
    ExtensionSubscribeFormWrapper,
    ExtensionTwitterEmbed,
    ExtensionSubscribeToPaidOfferWrapper,
];

export function renderTiptap(content: string | null): string {
    if (!content) {
        return '';
    }
    if (!isValidJSON(content)) {
        return '<p>Une erreur est survenue, veuillez réessayer plus tard</p>';
    }
    return generateHTML(JSON.parse(content), extensions);
}
