import { NodeViewWrapper } from '@tiptap/react';
import type { Node } from 'prosemirror-model';
import React from 'react';
import { TwitterTweetEmbed } from 'react-twitter-embed';

interface TwitterEmbedProps {
    node: Node;
}

export default function TwitterEmbed({ node }: TwitterEmbedProps): JSX.Element {
    function getTweetId(url: string): string {
        const idWithParams = url.split('/').pop();
        if (!idWithParams) {
            return '0';
        }
        return idWithParams.split('?')[0];
    }

    return (
        <NodeViewWrapper>
            <TwitterTweetEmbed tweetId={getTweetId(node.attrs.src)} />
        </NodeViewWrapper>
    );
}
