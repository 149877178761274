import type { ReactNode } from 'react';

interface CardProps {
    children: ReactNode;
    className?: string;
}

export default function Card({ children, className }: CardProps): JSX.Element {
    return (
        <div
            className={`bg-white border border-gray-100 rounded-lg shadow-md p-6 md:p-11 dark:bg-gray-800 dark:border-gray-700 ${className}`}>
            {children}
        </div>
    );
}
