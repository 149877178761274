import type { ReactNode } from 'react';

import classNames from '../../../lib/classnames';

export interface ProseProps {
    className?: string;
    children: ReactNode;
}

export default function Prose({ className, children }: ProseProps): JSX.Element {
    return (
        <div
            className={classNames(
                `
        prose max-w-none text-lg lg:prose-xl font-serif dark:prose-invert
        prose-h1:font-sans prose-h1:lg:text-4xl prose-h1:text-2xl
        prose-h2:font-sans prose-h2:text-lg prose-h2:lg:text-2xl prose-h2:mt-4 prose-h2:mb-2
        prose-h3:font-sans prose-h3:text-lg prose-h3:lg:text-xl
        prose-h4:font-sans prose-h4:text-lg
        prose-h5:font-sans prose-h5:text-base
        prose-h6:font-sans prose-h6:text-sm
        prose-lead:font-sans prose-lead:text-gray-500 prose-lead:lg:text-xl prose-lead:text-lg
        prose-figcaption:font-sans prose-figcaption:text-gray-500 prose-figcaption:!text-xs
        prose-figure:my-2 prose-figure:lg:my-8
        prose-img:my-2`,
                className,
            )}>
            {children}
        </div>
    );
}
