import type { Node } from 'prosemirror-model';
import React from 'react';

import EditorButtonLink from '../ButtonLinkWrapper';

interface MailButtonLinkProps {
    node: Node;
}
export default function MailButtonLink({ node }: MailButtonLinkProps): JSX.Element {
    return <EditorButtonLink node={node} />;
}
