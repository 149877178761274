import type { Content, Editor } from '@tiptap/react';
import { useEditor } from '@tiptap/react';

import { extensions } from './tiptap.service';

/**
 * This regex removes the attribute and its values from a text
 * for example: <span style="color: rgb(0, 0, 0)">text</span> will be converted to <span>text</span>
 * @param text
 */
function removeStyleAttribute(text: string): string {
    return text.replace(/(<[^>]+) style=".*?"/gi, '$1');
}

interface UserEditorInstanceProps {
    readOnly: boolean;
    content?: Content;
    onChange?: (content: string) => void;
}

export default function useEditorInstance({
    readOnly,
    content = null,
    onChange,
}: UserEditorInstanceProps): Editor | null {
    return useEditor({
        editable: !readOnly,
        extensions,
        onUpdate({ editor }) {
            onChange?.(JSON.stringify(editor.getJSON()));
        },
        editorProps: {
            transformPastedHTML(html) {
                return removeStyleAttribute(html);
            },
            transformPastedText(text) {
                return removeStyleAttribute(text);
            },
        },
        content,
    });
}
