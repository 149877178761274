import { NodeViewWrapper } from '@tiptap/react';
import type { Node } from 'prosemirror-model';
import React from 'react';

import { isValidJSON } from '../../../../lib/helper/helper';
import PayWall from '../../../modules/Posts/PayWall';
import { parsePublicationForSubscriberInput } from '../../../modules/SubscriberFormInput/SubscriberFormInput.service';

interface PaywallWrapperProps {
    node: Node;
}

export default function PaywallWrapper({ node }: PaywallWrapperProps): JSX.Element | null {
    if (!isValidJSON(node.attrs.publication)) {
        return null;
    }

    return (
        <NodeViewWrapper>
            <PayWall
                publication={parsePublicationForSubscriberInput(node.attrs.publication)}
                postId={node.attrs.postId}
            />
        </NodeViewWrapper>
    );
}
