import type { Editor } from '@tiptap/react';
import ArrowClockWise from 'bootstrap-icons/icons/arrow-clockwise.svg';
import ArrowCounterClockWise from 'bootstrap-icons/icons/arrow-counterclockwise.svg';
import CardImage from 'bootstrap-icons/icons/card-image.svg';
import CodeSlash from 'bootstrap-icons/icons/code-slash.svg';
import Link45Deg from 'bootstrap-icons/icons/link-45deg.svg';
import ListOl from 'bootstrap-icons/icons/list-ol.svg';
import Paragraph from 'bootstrap-icons/icons/paragraph.svg';
import Quote from 'bootstrap-icons/icons/quote.svg';
import TextCenter from 'bootstrap-icons/icons/text-center.svg';
import TextLeft from 'bootstrap-icons/icons/text-left.svg';
import TextRight from 'bootstrap-icons/icons/text-right.svg';
import TypeBold from 'bootstrap-icons/icons/type-bold.svg';
import Typeh1 from 'bootstrap-icons/icons/type-h1.svg';
import Typeh2 from 'bootstrap-icons/icons/type-h2.svg';
import Typeh3 from 'bootstrap-icons/icons/type-h3.svg';
import TypeItalic from 'bootstrap-icons/icons/type-italic.svg';
import TypeUnderline from 'bootstrap-icons/icons/type-underline.svg';
import Youtube from 'bootstrap-icons/icons/youtube.svg';
import { useSession } from 'next-auth/react';
import React, { useCallback, useRef } from 'react';

import classNames from '../../../lib/classnames';
import { isCurrentBrowserFirefox } from '../../../lib/helper/helper';
import { postAxiosAPI } from '../../../lib/request';
import type IMedia from '../../../shared/interfaces/media.interface';
import Dropdown from '../../basic/Dropdown';
import Button from '../../elements/Button';
import Unlink from '../../svgs/illustrations/unlink-thin.svg';
import type { TiptapProps } from '../index';
import styles from './MenuBar.module.css';
import MoreButton from './MoreButton';

async function upload(file: File, userToken: string): Promise<IMedia[]> {
    const formData = new FormData();
    formData.append('files', file);
    const response = await postAxiosAPI<IMedia[]>('/upload', formData, userToken);
    return response.data;
}

function setYoutubeVideo(editor: Editor): void {
    const previousUrl = editor.getAttributes('link').href;
    // eslint-disable-next-line no-alert
    const url = window.prompt('Youtube URL', previousUrl);

    if (url === null || url === '') {
        return;
    }

    editor.commands.setYoutubeVideo({ src: url });
}

function setTextAlign(type: 'right' | 'left' | 'center', editor: Editor): void {
    const prefix = isCurrentBrowserFirefox() ? '-moz-' : '-webkit-';
    editor.chain().focus();

    if (editor.isActive({ textAlign: `${prefix}${type}` })) {
        editor.commands.unsetTextAlign();
    } else {
        editor.commands.setTextAlign(`${prefix}${type}`);
    }
}

export interface MenuBarProps extends Omit<TiptapProps, 'editor'> {
    editor: Editor;
}

export default function MenuBar({ editor, publication, postId }: MenuBarProps): JSX.Element {
    const hiddenFileInput = useRef<HTMLInputElement | null>(null);
    const { data: session } = useSession({ required: true });

    async function handleChange(e: React.ChangeEvent<HTMLInputElement>): Promise<void> {
        if (!e?.target?.files?.[0]) {
            return;
        }
        if (!session) {
            return;
        }
        try {
            const [image] = await upload(e.target.files[0], session.jwt);

            editor.chain().focus().setImage({ src: image.url }).run();
        } catch (error) {
            console.error(error);
        }
    }

    const setLink = useCallback(() => {
        const previousUrl = editor.getAttributes('link').href;
        // eslint-disable-next-line no-alert
        const url = window.prompt('URL', previousUrl);

        // cancelled
        if (url === null) {
            return;
        }

        // empty
        if (url === '') {
            editor.chain().focus().extendMarkRange('link').unsetLink().run();

            return;
        }

        // update link
        editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run();
    }, [editor]);

    if (!editor) {
        return <div>No editor</div>;
    }
    // TODO add aria label for those icons
    return (
        <div
            className={classNames(styles.menuBar, 'dark:bg-gray-800', {
                'top-[60px] md:top-[95px] bg-white z-10 !sticky': publication,
            })}>
            {/* Bold text */}
            <Button
                color="borderless"
                onClick={() => editor.chain().focus().toggleBold().run()}
                className={editor.isActive('bold') ? 'is-active' : ''}>
                <TypeBold />
            </Button>
            {/* italic text */}
            <Button
                color="borderless"
                onClick={() => editor.chain().focus().toggleItalic().run()}
                className={editor.isActive('italic') ? 'is-active' : ''}>
                <TypeItalic />
            </Button>
            {/* code text */}
            <Button
                color="borderless"
                onClick={() => editor.chain().focus().toggleCode().run()}
                className={editor.isActive('code') ? 'is-active' : ''}>
                <CodeSlash />
            </Button>
            {/* link */}
            <Button
                color="borderless"
                onClick={setLink}
                className={editor.isActive('link') ? 'is-active' : ''}>
                <Link45Deg />
            </Button>
            {/* unlink */}
            <Button
                color="borderless"
                onClick={() => editor.chain().focus().unsetLink().run()}
                disabled={!editor.isActive('link')}>
                <Unlink />
            </Button>
            {/* youtube */}
            <Button
                color="borderless"
                onClick={() => setYoutubeVideo(editor)}
                className={editor.isActive('youtube') ? 'is-active' : ''}>
                <Youtube />
            </Button>
            <div className={styles.divider} />
            {/* Text align left */}
            <Button
                color="borderless"
                onClick={() => setTextAlign('left', editor)}
                className={editor.isActive({ textAlign: 'left' }) ? 'is-active' : ''}>
                <TextLeft />
            </Button>
            {/* Text align center */}
            <Button
                color="borderless"
                onClick={() => setTextAlign('center', editor)}
                className={editor.isActive({ textAlign: 'center' }) ? 'is-active' : ''}>
                <TextCenter />
            </Button>
            {/* Text align right */}
            <Button
                color="borderless"
                onClick={() => setTextAlign('right', editor)}
                className={editor.isActive({ textAlign: 'right' }) ? 'is-active' : ''}>
                <TextRight />
            </Button>
            <div className={styles.divider} />
            <Button color="borderless" onClick={() => hiddenFileInput?.current?.click()}>
                <CardImage />
            </Button>
            <input
                ref={hiddenFileInput}
                type="file"
                id="file-input"
                accept="image/*"
                onChange={handleChange}
                style={{ display: 'none' }}
            />
            {/* underline */}
            <Button
                color="borderless"
                onClick={() => editor.chain().focus().toggleUnderline().run()}
                className={editor.isActive('underline') ? 'is-active' : ''}>
                <TypeUnderline />
            </Button>

            {publication && (
                <MoreButton editor={editor} publication={publication} postId={postId} />
            )}

            <Dropdown
                links={[
                    {
                        id: 0,
                        children: <Paragraph />,
                        onClick: () => {
                            editor.chain().focus().setParagraph().run();
                        },
                    },
                    {
                        id: 1,
                        children: <Typeh1 />,
                        onClick: () => {
                            editor.chain().focus().toggleHeading({ level: 1 }).run();
                        },
                    },
                    {
                        id: 2,
                        children: <Typeh2 />,
                        onClick: () => {
                            editor.chain().focus().toggleHeading({ level: 2 }).run();
                        },
                    },
                    {
                        id: 3,
                        children: <Typeh3 />,
                        onClick: () => {
                            editor.chain().focus().toggleHeading({ level: 3 }).run();
                        },
                    },
                ]}>
                Styles
            </Dropdown>

            <Button
                color="borderless"
                onClick={() => editor.chain().focus().toggleBulletList().run()}
                className={editor.isActive('bulletList') ? 'is-active' : ''}>
                <ListOl />
            </Button>
            <Button
                color="borderless"
                onClick={() => editor.chain().focus().toggleOrderedList().run()}
                className={editor.isActive('orderedList') ? 'is-active' : ''}>
                <ListOl />
            </Button>
            <Button
                color="borderless"
                onClick={() => editor.chain().focus().toggleBlockquote().run()}
                className={editor.isActive('blockquote') ? 'is-active' : ''}>
                <Quote />
            </Button>
            <div className={styles.divider} />
            <Button color="borderless" onClick={() => editor.chain().focus().undo().run()}>
                <ArrowCounterClockWise />
            </Button>
            <Button color="borderless" onClick={() => editor.chain().focus().redo().run()}>
                <ArrowClockWise />
            </Button>
        </div>
    );
}
