import { NodeViewWrapper } from '@tiptap/react';
import type { Node } from 'prosemirror-model';
import React from 'react';

import { isValidJSON } from '../../../../lib/helper/helper';
import SubscriberForm from '../../../modules/SubscriberForm';
import { parsePublicationForSubscriberInput } from '../../../modules/SubscriberFormInput/SubscriberFormInput.service';
import styles from '../../Tiptap.module.css';

interface SubscribeFormWrapperProps {
    node: Node;
}

export default function SubscribeFormWrapper({
    node,
}: SubscribeFormWrapperProps): JSX.Element | null {
    if (!isValidJSON(node.attrs.publication)) {
        return null;
    }

    return (
        <NodeViewWrapper>
            <SubscriberForm
                buttonClassName={styles.editorButton}
                publication={parsePublicationForSubscriberInput(node.attrs.publication)}
                alreadySubscribeClassName="text-center"
            />
        </NodeViewWrapper>
    );
}
