import { mergeAttributes, Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import PaywallWrapper from '.';

export const PaywallName = 'paywallWrapper';
export const PaywallTag = 'paywall-wrapper';

export default Node.create({
    name: PaywallName,

    group: 'block',

    atom: true,

    addAttributes() {
        return {
            publication: {
                default: undefined,
            },
            postId: {
                default: undefined,
            },
        };
    },

    parseHTML() {
        return [
            {
                tag: PaywallTag,
            },
        ];
    },

    renderHTML({ HTMLAttributes }) {
        return [PaywallTag, mergeAttributes(HTMLAttributes)];
    },

    addNodeView() {
        return ReactNodeViewRenderer(PaywallWrapper);
    },
});
