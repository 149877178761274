import { mergeAttributes, Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import MailLinkButton from '.';

export const MailLinkButtonName = 'mailLinkButton';
export const MailLinkButtonTag = 'mail-link-button';

export default Node.create({
    name: MailLinkButtonName,

    group: 'block',

    atom: true,

    addAttributes() {
        return {
            href: {
                default: undefined,
            },
            label: {
                default: undefined,
            },
        };
    },

    parseHTML() {
        return [
            {
                tag: MailLinkButtonTag,
            },
        ];
    },

    renderHTML({ HTMLAttributes }) {
        return [MailLinkButtonTag, mergeAttributes(HTMLAttributes)];
    },

    addNodeView() {
        return ReactNodeViewRenderer(MailLinkButton);
    },
});
