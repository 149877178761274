import type { ReactNode } from 'react';

export interface LabelProps {
    children: ReactNode;
    htmlFor?: string;
    className?: string;
}

export default function Label({ htmlFor, children, className }: LabelProps): JSX.Element {
    return (
        <label
            htmlFor={htmlFor}
            className={`block mb-2 text-sm font-bold text-gray-900 dark:text-gray-300 ${className}`}>
            {children}
        </label>
    );
}
