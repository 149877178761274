import { mergeAttributes, Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import SubscribeToPaidOfferWrapper from '.';
import type { IPublication } from '../../../../features/publication';

export function formatPublicationForSubscriberToPaidOffer(
    publication: Pick<IPublication, 'id' | 'slug'>,
): string {
    return JSON.stringify(publication);
}

export const SubscribeToPaidOfferName = 'subscribeToPaidOfferWrapper';
export const SubscribeToPaidOfferTag = 'subscribe-to-paid-offer-wrapper';

export default Node.create({
    name: SubscribeToPaidOfferName,

    group: 'block',

    atom: true,

    addAttributes() {
        return {
            publication: {
                default: undefined,
            },
            postId: {
                default: undefined,
            },
            price: {
                default: undefined,
            },
        };
    },

    parseHTML() {
        return [
            {
                tag: SubscribeToPaidOfferTag,
            },
        ];
    },

    renderHTML({ HTMLAttributes }) {
        return [SubscribeToPaidOfferTag, mergeAttributes(HTMLAttributes)];
    },

    addNodeView() {
        return ReactNodeViewRenderer(SubscribeToPaidOfferWrapper);
    },
});
