import { mergeAttributes, Node, nodePasteRule } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';
import type { ParseOptions } from 'querystring';

import TwitterEmbed from './index';
import { isValidTwitterUrl, TWITTER_REGEX_GLOBAL } from './utils';

// TODO this type is not 100% correct, check here later how we can type this https://tiptap.dev/guide/typescript#command-type
declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        customExtension: {
            /**
             * Comments will be added to the autocomplete.
             */
            setTwitterEmbed: (someProp: {
                parseOptions?: ParseOptions;
                updateSelection?: boolean;
                src: string;
            }) => ReturnType;
        };
    }
}

export default Node.create({
    name: 'twitter-embed',

    addOptions() {
        return {
            addPasteHandler: true,
            allowFullscreen: false,
            controls: true,
            height: 300,
            HTMLAttributes: {},
            inline: false,
            nocookie: false,
            width: 600,
        };
    },

    inline() {
        return this.options.inline;
    },

    group() {
        return this.options.inline ? 'inline' : 'block';
    },

    atom: true,

    draggable: true,

    addAttributes() {
        return {
            src: {
                default: undefined,
            },
            width: {
                default: this.options.width,
            },
            height: {
                default: this.options.height,
            },
        };
    },

    addCommands() {
        return {
            setTwitterEmbed:
                (options) =>
                ({ commands }) => {
                    if (!isValidTwitterUrl(options.src)) {
                        return false;
                    }

                    return commands.insertContent({
                        type: this.name,
                        attrs: options,
                    });
                },
        };
    },

    addPasteRules() {
        if (!this.options.addPasteHandler) {
            return [];
        }

        return [
            nodePasteRule({
                find: TWITTER_REGEX_GLOBAL,
                type: this.type,
                getAttributes: (match) => ({ src: match.input }),
            }),
        ];
    },

    parseHTML() {
        return [
            {
                tag: 'twitter-embed',
            },
        ];
    },

    renderHTML({ HTMLAttributes }) {
        return ['twitter-embed', mergeAttributes(HTMLAttributes)];
    },

    addNodeView() {
        return ReactNodeViewRenderer(TwitterEmbed);
    },
});
