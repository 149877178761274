import type { CSSProperties, ReactNode } from 'react';
import { useEffect, useRef, useState } from 'react';

import classNames from '../../../lib/classnames';

type Emplacement = 'bottom' | 'bottom-left' | 'top' | 'right-top' | 'left-top';

function getStyle(
    emplacement: Emplacement,
    buttonWidth: number,
    buttonHeight: number,
    gap: number,
): CSSProperties {
    if (emplacement === 'bottom') {
        return {
            left: `${buttonWidth / 2}px`,
            top: `${buttonHeight + gap}px`,
            transform: 'translateX(-50%)',
        };
    }
    if (emplacement === 'top') {
        return {
            left: `${buttonWidth / 2}px`,
            bottom: `${buttonHeight + gap}px`,
            transform: 'translateX(-50%)',
        };
    }
    if (emplacement === 'right-top') {
        return {
            bottom: 0,
            left: `${buttonWidth + gap}px`,
        };
    }
    if (emplacement === 'left-top') {
        return {
            bottom: 0,
            right: `${buttonWidth + gap}px`,
        };
    }
    if (emplacement === 'bottom-left') {
        return {
            top: 0,
            right: `${buttonWidth + gap}px`,
        };
    }
    return {
        left: 0,
    };
}

export interface GenericDropDownProps {
    wrapperClassName?: string;
    buttonClassName?: string;
    itemsClassName?: string;
    buttonNode: ReactNode;
    children: ReactNode;
    emplacement?: Emplacement;
    gap?: number;
    disabled?: boolean;
}

export default function GenericDropDown({
    wrapperClassName,
    buttonClassName,
    buttonNode,
    itemsClassName,
    children,
    disabled,
    emplacement = 'bottom',
    gap = 10,
}: GenericDropDownProps): JSX.Element {
    const [isOpen, setIsOpen] = useState(false);

    const wrapperRef = useRef<HTMLDivElement>(null);

    const buttonRef = useRef<HTMLButtonElement>(null);
    const [buttonWidth, setButtonWidth] = useState(0);
    const [buttonHeight, setButtonHeight] = useState(0);

    useEffect(() => {
        function handleClickOutside({ target }: MouseEvent): void {
            if (target instanceof Node) {
                if (!wrapperRef?.current?.contains(target)) {
                    setIsOpen(false);
                }
            }
        }

        document.addEventListener('click', handleClickOutside, true);
        return () => document.removeEventListener('click', handleClickOutside, true);
    }, [wrapperRef]);

    // TODO check about useLayoutEffect and its implications for server side rendering
    useEffect(() => {
        if (buttonRef?.current) {
            setButtonWidth(buttonRef.current.offsetWidth);
            setButtonHeight(buttonRef.current.offsetHeight);
        }
    }, []);

    return (
        <div ref={wrapperRef} className={classNames('relative', wrapperClassName)}>
            <button
                ref={buttonRef}
                type="button"
                disabled={disabled}
                onClick={() => setIsOpen(!isOpen)}
                className={classNames(buttonClassName)}>
                {buttonNode}
            </button>
            <div
                className={classNames({ hidden: !isOpen }, 'absolute z-50', itemsClassName)}
                style={getStyle(emplacement, buttonWidth, buttonHeight, gap)}>
                {children}
            </div>
        </div>
    );
}
