import { NodeViewWrapper } from '@tiptap/react';
import type { Node } from 'prosemirror-model';
import React from 'react';

import classNames from '../../../../lib/classnames';
import Button from '../../../elements/Button';
import styles from '../../Tiptap.module.css';

interface EditorButtonLinkProps {
    node: Node;
}

export default function EditorButtonLink({ node }: EditorButtonLinkProps): JSX.Element {
    return (
        <NodeViewWrapper>
            <div className="flex justify-center w-full">
                <Button
                    onClick={() => window.open(node.attrs.href, '_blank')}
                    className={classNames('mx-1', styles.editorButton)}>
                    {node.attrs.label}
                </Button>
            </div>
        </NodeViewWrapper>
    );
}
