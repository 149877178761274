import React, { useEffect, useState } from 'react';

import routes from '../../../../config/routes';
import Dropdown from '../../../basic/Dropdown';
import type { SubscriberInputPublication } from '../../../modules/SubscriberFormInput/SubscriberFormInput.interface';
import { MailLinkButtonTag } from '../../Extensions/MailButtonLink/ExtensionMailButtonLink';
import { PaywallTag } from '../../Extensions/PaywallWrapper/ExtensionPaywallWrapper';
import { SubscribeFormTag } from '../../Extensions/SubscribeFormWrapper/ExtensionSubscribeFormWrapper';
import {
    formatPublicationForSubscriberToPaidOffer,
    SubscribeToPaidOfferTag,
} from '../../Extensions/SubscribeToPaidOfferWrapper/ExtensionSubscribeToPaidOfferWrapper';
import FormCustomButton from '../FormCustomButton';
import type { MenuBarProps } from '../index';
import { formatPublication, handlePaidMode } from './MoreButton.service';

interface MoreButtonProps extends MenuBarProps {
    publication: SubscriberInputPublication;
}

export default function MoreButton({ editor, postId, publication }: MoreButtonProps): JSX.Element {
    const [buttonMakerVisibility, setButtonMakerVisibility] = useState(false);

    const [isPublicationPaid, setIsPublicationPaid] = useState(false);

    useEffect(() => {
        handlePaidMode(publication, setIsPublicationPaid);
    }, [publication]);

    const links = [
        {
            id: 0,
            children: 'Formulaire d’inscription',
            onClick: () => {
                editor
                    .chain()
                    .focus()
                    .insertContent(
                        `<${SubscribeFormTag} publication='${formatPublication(
                            publication,
                        )}' postId='${postId}'></${SubscribeFormTag}>`,
                    )
                    .run();
            },
        },
        {
            id: 1,
            children: 'Bouton personnalisé',
            onClick: () => {
                setButtonMakerVisibility(true);
            },
        },
        {
            id: 2,
            children: 'Voir en ligne',
            onClick: () => {
                editor
                    .chain()
                    .focus()
                    .insertContent(
                        `<${MailLinkButtonTag} href="${routes.getPost.getUrl(
                            publication.id,
                            postId,
                        )}" label="Voir en ligne"></${MailLinkButtonTag}>`,
                    )
                    .run();
            },
        },
        {
            id: 3,
            children: 'Commenter en ligne',
            onClick: () => {
                editor
                    .chain()
                    .focus()
                    .insertContent(
                        `<${MailLinkButtonTag} href="${routes.getPost.getUrl(
                            publication.id,
                            postId,
                        )}/#comments" label="Commenter en ligne"></${MailLinkButtonTag}>`,
                    )
                    .run();
            },
        },
    ];

    if (isPublicationPaid) {
        links.push(
            {
                id: 4,
                children: 'Ajouter un paywall',
                onClick: () => {
                    editor
                        .chain()
                        .focus()
                        .insertContent(
                            `<${PaywallTag} publication='${formatPublication(
                                publication,
                            )}' postId='${postId}'></${PaywallTag}>`,
                        )
                        .run();
                },
            },
            {
                id: 5,
                children: 'Bouton Accès complet',
                onClick: () => {
                    editor
                        .chain()
                        .focus()
                        .insertContent(
                            `<${SubscribeToPaidOfferTag} publication='${formatPublicationForSubscriberToPaidOffer(
                                publication,
                            )}' postId='${postId}'></${SubscribeToPaidOfferTag}>`,
                        )
                        .run();
                },
            },
        );
    }

    return (
        <>
            <FormCustomButton
                editor={editor}
                visible={buttonMakerVisibility}
                setVisible={setButtonMakerVisibility}
            />
            <Dropdown links={links}>Plus</Dropdown>
        </>
    );
}
