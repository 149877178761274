import type { ReactNode } from 'react';

import classNames from '../../../lib/classnames';
import type { NavOptionProps } from '../../modules/Navigation/BackendNav/NavOption';
import NavOption from '../../modules/Navigation/BackendNav/NavOption';
import GenericDropDown from '../GenericDropDown';

export interface DropdownProps {
    className?: string;
    children: ReactNode;
    links: Array<NavOptionProps & { id: number }>;
}

export default function Dropdown({ className, children, links }: DropdownProps): JSX.Element {
    return (
        <GenericDropDown
            buttonClassName={classNames(
                'flex items-center justify-between w-full py-2 pl-3 text-xs font-bold text-gray-700 lg:text-sm dark:text-gray-400 dark:hover:text-white dark:focus:text-white md:p-0 md:w-auto md:hover:text-blue-700 md:hover:bg-transparent md:dark:hover:bg-transparent',
                className,
            )}
            buttonNode={
                <>
                    {children}
                    <svg
                        className="w-4 h-4 ml-1"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            fillRule="evenodd"
                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                            clipRule="evenodd"
                        />
                    </svg>
                </>
            }
            itemsClassName="mt-2 w-44 bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700 dark:divide-gray-600">
            <ul className="py-1 text-sm text-gray-700 dark:text-gray-400">
                {links.map((link) => (
                    <li key={link.id}>
                        <NavOption
                            getUrl={link.getUrl}
                            onClick={link.onClick}
                            className="block px-4 py-2 text-left text-sm dark:text-gray-300 dark:hover:text-white hover:bg-gray-100 dark:hover:bg-gray-600">
                            {link.children}
                        </NavOption>
                    </li>
                ))}
            </ul>
        </GenericDropDown>
    );
}
