import { Image } from '@tiptap/extension-image';
import { mergeAttributes } from '@tiptap/react';

const CustomImage = Image.extend({
    addOptions() {
        return Image.options;
    },
    renderHTML({ HTMLAttributes }) {
        return [
            'figure',
            HTMLAttributes,
            ['img', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)],
        ];
    },
});

export default CustomImage;
