import type { ReactNode } from 'react';

import classNames from '../../../../../lib/classnames';
import Button from '../../../../elements/Button';
import Link from '../../../../elements/Link';

// export type NavOptionProps = {
//     className?: string;
//     children: ReactNode;
// } & ({ onClick: () => void; getUrl?: never } | { onClick?: never; getUrl: () => string });

export type NavOptionProps = {
    className?: string;
    children: ReactNode;
    onClick?: () => void;
    getUrl?: (...params: string[]) => string;
    target?: string;
};

export default function NavOption({
    getUrl,
    className,
    children,
    onClick,
    target
}: NavOptionProps): JSX.Element {
    if (getUrl) {
        return (
            <Link href={getUrl()} type="alternative" target={target} onClick={onClick}>
                <span className={classNames('hover:cursor-pointer', className)}>{children}</span>
            </Link>
        );
    }

    if (onClick) {
        return (
            <Button
                color="transparent"
                size="no-size"
                className={classNames('w-full', className)}
                onClick={onClick}>
                {children}
            </Button>
        );
    }

    return <div>{children}</div>;
}
